export const MenuItems = [
    {
      title: 'About Us',
      path: '/about-us',
      cName: 'dropdown-link'
    },
    {
      title: 'Leadership',
      path: '/leadership',
      cName: 'dropdown-link'
    },
    {
      title: 'Careers',
      path: '/careers',
      cName: 'dropdown-link'
    },
  ];