import React from 'react';
import '../../assets/css/bootstrap.min.css';

const APISection = () => {

    const imgattr = 'SendFriend'

    return (
        <>
        <div className="features-section bg-green pd-top-190">
            <img alt={imgattr} className="bottom-image" src="img/homepage/dark-bg-tilted.png" />
            <div id="partners" className="partners-section">
                <div className="partnerships-table">
                    <h3 className="partners-text">Partners</h3>
                    <p className="partners-sub">
                        We are fortunate to be parterned with the following leaders in our space and global influencers of financial
                        technology:
                    </p>
                    <div className="bg-border"></div>
                    <div className="group-icons">
                        <img alt={imgattr} src="img/homepage/partners-group.svg" />
                    </div>
                </div>
            </div>
        </div>
       </>
    );
}

export default APISection;