import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import "../components/Navbar.css";
import Nav from '../components/Navs/Nav';
import NavMobile from '../components/Navs/NavMobile';
import Footer from '../components/Footer/Footer';
import FooterMobile from '../components/Footer/FooterMobile';
import Client from '../components/Clients/clients';
import HeaderTest from '../components/Headers/HeaderTest';
import APISection from '../components/Homepage/APISection';


const Homepage = () => {
    const imgattr = 'SendFriend'

    return (
      <div>
       <div class="udaap screen">
         <Nav />
         <HeaderTest 
            text=" Faster, Cheaper Cross-Border Payments"
            green_text='Service.'
            subtitle='SendFriend'
            image='img/homepage/slider-homepage.jpg'
            under_text='We are cheaper, faster, and more reliable and secure.'
            button={true}
          />
        <APISection />
        <Client />
      <Footer custom_class="footer-section" />
    </div>

    <div class="mobile-homepage screen">
      <NavMobile />
      <Client />
      {/* Header  */}
      <img alt={imgattr} class="slider-homepage-1-qujsfd bp2-animate-enter1" src="img/slider-homepage-1@1x.jpg" />
      <div class="rectangle-126-qujsfd"></div>
      <div class="faster-cheaper-multi-qujsfd bp2-animate-enter2">
        <span class="span0-GBW4Tf">Faster, Cheaper<br />Cross-Border<br />Payments </span
        ><span class="span1-GBW4Tf montserrat-extra-bold-caribbean-green-30px">Service.</span>
      </div>
      <div class="we-are-cheaper-faste-qujsfd montserrat-semi-bold-white-16px bp2-animate-enter3" >
        We are cheaper, faster, and more reliable and secure.
      </div>
      <div class="contact-us-qujsfd bp2-animate-enter4" >
      <Link to="/contact-us">
        <div class="rectangle-1-kXqD6c smart-layers-pointers"></div>
        <div class="contact-us-kXqD6c valign-text-middle montserrat-bold-eerie-black-11px">CONTACT US</div>
        </Link>
      </div>
      {/* Header End */}
      <img alt={imgattr} class="rectangle-106-qujsfd" src="img/rectangle-106-1@1x.png" />

      <div id="partners-mobile" class="partnerships-qujsfd">
        <div class="rectangle-91-RBdLCu"></div>
        <img alt={imgattr} class="line-2-RBdLCu" src="img/line-2@2x.png" />
        <div class="group-100-RBdLCu">
          <div class="group-44-nrzUja">
            <div class="artboard-1-1-vVV7dw">
              <img alt={imgattr} class="bottom_-ring-ZLM6y5" src="img/bottom-ring-2@2x.png" />
              <img alt={imgattr} class="top_-ring-ZLM6y5" src="img/top-ring-2@2x.png" />
              <img alt={imgattr} class="rings_-overlap-ZLM6y5" src="img/rings-overlap-2@2x.png" />
            </div>
            <div class="rippl-vVV7dw">
              <img alt={imgattr} class="vector-8Jmrad" src="img/homepage/vector-35@2x.png" />
              <img alt={imgattr} class="vector-zQfLsx" src="img/homepage/vector-36@2x.png" />
              <img alt={imgattr} class="vector-nAYXPr" src="img/homepage/vector-37@2x.png" />
              <img alt={imgattr} class="vector-UExn7f" src="img/homepage/vector-38@2x.png" />
              <img alt={imgattr} class="vector-DdwQzm" src="img/homepage/vector-39@2x.png" />
              <img alt={imgattr} class="vector-4yxsnS" src="img/homepage/vector-40@2x.png" />
              <img alt={imgattr} class="vector-h8WzEb" src="img/homepage/vector-41@2x.png" />
            </div>
            <img alt={imgattr} class="celo-vVV7dw" src="img/celo@2x.png" />
            <HashLink smooth to="#top">
            <img alt={imgattr} class="logo_footer-1-vVV7dw" src="img/logo-footer-1@2x.png" />
            </HashLink>
          </div>
          <div class="group-45-nrzUja">
            <img alt={imgattr} class="vector-nW5wWj" src="img/homepage/vector-71@2x.png" />
            <img alt={imgattr} class="d-au1-v-vb-visgik1-y-nW5wWj" src="img/homepage/mitdeltav.png"
            />
            <img alt={imgattr} class="vector-Hygbyv" src="img/homepage/group-45@1x.png" />
            <img alt={imgattr} class="european-funds-1-nW5wWj" src="img/european-funds-1-1@2x.png" />
            <img alt={imgattr} class="en-logo-1-nW5wWj" src="img/enlogo-1-2@2x.png" />
            <img alt={imgattr} class="knick-capital-1-nW5wWj" src="img/knick-capital-1-2@2x.png" />
            <img alt={imgattr} class="coins-ph-1-nW5wWj" src="img/coins-ph-1-2@2x.png" />
            <img alt={imgattr} class="barclays_-accelerator_-logo_16_9medium-1-nW5wWj" src="img/barclays-accelerator-logo-16-9-medium-1-2@2x.png"
            />
          </div>
        </div>
        <div class="we-are-fortunate-to-RBdLCu">
          We are fortunate to be parterned with the following leaders in our space and global influencers of financial
          technology:
        </div>
        <div class="partners-RBdLCu montserrat-extra-bold-caribbean-green-21px">Partners</div>
      </div>
      <div class="send-friend-qujsfd montserrat-bold-caribbean-green-18px bp2-animate-enter21" >
        SendFriend
      </div>
      <FooterMobile custom_class="footer footer-homepage" />

    </div>             
        </div>
        
        )
        
    }

export default Homepage;


 
